<template>
  <company-attr v-model="value.link" @editing="toggleEdit" :startEdit="startEdit"
    @submitted="submitted"
  >
    <template v-slot:label>
      <v-select
          class="small-vs"
          :clearable="false"
          :searchable="false"
          :options="siteOptions"
          placeholder="Site"
          :create-option="site => (site)"
          v-model="value.site"
          label="name"
          v-if="editing"
        >

      </v-select>
        {{!editing? value.site : ''}}
    </template>
    <template v-slot:outer>
      <div class="delete-cont" @click="() => remove()">
        <img :src="require('@/assets/pictos/picto_purple_trash.svg')" />
      </div>
    </template>
  </company-attr>
</template>

<script>
import companyAttr from './companyAttr.vue';

export default {
  name: 'CompanySocial',
  components: { companyAttr },
  data() {
    return {
      editing: this.startEdit,
      addition: '',
      siteOptions: ['LinkedIn', 'Github', 'TikTok', 'Facebook', 'YouTube', 'Reddit'],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    remove: {
      type: Function,
      default: () => {},
    },
    startEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleEdit() {
      this.editing = !this.editing;
      if (!this.editing && (!this.value.site || !this.value.link)) {
        this.remove();
      }
    },
    submitted() {
      this.$emit('submitted');
    },
    addOption() {
      this.siteOptions.push(this.addition);
      this.addition = '';
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('input', this.value);
      },
    },
  },

};
</script>

<style lang="scss">
.small-vs{
  width: 80% !important;
}
.delete-cont{
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  img{
    width: 100%;
  }
}
.add-input{
  width: 80%;
  padding-left: 1vh;
  input{
    padding: .5vh;
    width: 100%;
  }
}
</style>
