<template>
  <div class="company-attr box-shadow" :class="{darker: this.darker}">
    <div class="display-company" >
        <div class="label">
          <slot name="label">
            {{label}}
          </slot>
        </div>
      <div class="divider" />
      <!-- For Normal Input  -->
     <template v-if="!this.type">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <input v-model="editVal" />
        </div>
     </template>
     <!-- For Autocomplete -->
     <template v-if="this.type === 'autocomplete'">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <autocomplete v-model="editVal" :terms="autoCompleteTerms" />
        </div>
     </template>

     <!-- Number -->
     <template v-if="this.type === 'number'">
        <div class="val" v-if="!editing ">
          {{this.value}}
        </div>
        <div class="val" v-if="editing">
          <input type="number" v-model.number="editVal" />
        </div>
     </template>

      <!-- For HTML Input  -->
     <template v-else-if="this.type === 'html'">
        <div class="val" v-if="!editing" v-html="this.value">
          <!-- <div class="html-val" /> -->
        </div>
        <div class="val" v-if="editing">
          <wysiwyg class="wysiwyg radius" v-model="editVal" />
        </div>
     </template>

     <!-- For media Input  -->
     <template v-else-if="this.type === 'media'">
        <div class="val media" v-if="!editing ">
          <img :src="this.value" />
        </div>
     </template>
     <template v-else-if="this.type === 'custom'">
        <slot name="display" v-if="!editing "></slot>
        <slot name="edit" v-else></slot>
     </template>

      <div class="button-comp-cont">
        <div class="edit-button radius-small editing" @click="submitChange" v-if="editing">
          Submit
        </div>
        <div class="edit-button small radius-small red editing"
          @click="toggleEdit"
          v-if="editing"
        >
          Cancel
        </div>
        <div class="edit-button small radius-small edit-btn"
          @click="toggleEdit"
          v-else
        >
          Edit
        </div>
      </div>

      <div class="outer" v-if="editing">
        <slot name="outer"></slot>
      </div>
    </div>

  </div>
</template>

<script>
import Autocomplete from '@/components/utilityPartials/_autocomplete.vue';
import selectMedia from '@/modules/mixins/selectMedia';

export default {
  name: 'CompanyAttribute',
  mixins: [selectMedia],
  components: {
    Autocomplete,
  },
  props: {
    value: {
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    darker: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    startEdit: {
      type: Boolean,
      default: false,
    },
    autoCompleteTerms: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      editing: this.startEdit,
      editVal: this.value,
    };
  },

  methods: {
    toggleEdit() {
      if (this.type === 'media') {
        this.attachImage((imgFile) => {
          this.$emit('input', imgFile.data);
        });
      } else {
        this.$emit('editing');
        this.editing = !this.editing;
        this.editVal = this.value;
      }
    },
    submitChange() {
      this.$emit('input', this.editVal);
      this.$emit('submitted');
      this.toggleEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.company-attr{
  width: calc(100% - 5vw);
  position: relative;
  padding: 1vh;
  border-radius: 1vh;
  line-height: 4vh;
  font-size: 1.8vh;
  vertical-align: middle;
  margin-bottom: 1vh;

  input{
    border: 1px solid $border_darkgray;
    border-radius: 0.5vh;
  }

  &.darker{
    background-color:#f8f8f8;

  }
  .display-company{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    position: relative;
    height: 100%;

    .label{
      width: 25%;
      font-weight: 800;
      margin-left: 3%;
      margin-right: 1vw;
    }
    .divider{
      top: -1vh;
      height: calc(100% + 2vh);
      left: calc(25% + 2%);
      position: absolute;
      background-color: blueviolet;
      width: 3px;
    }
    .val{
      width: 60%;
      color: #707070;
      vertical-align: center;
      word-wrap: break-word;
      img{
        max-height: 10vh;
        width: auto
      }
      input{
        padding: 1vh;
        width: 90%;
        // border: 0px;
      }
    }
    .html-val{
      line-height: 2vh;
    }
    .outer{
      width: 1vw;
      height: 1vw;
      position: absolute;
      right: -2vw;
      top: -1vw;
    }
    .button-comp-cont{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-self: center;
      position: absolute;
      right: 0;
      margin-left: 1vw;

      .edit-button{
        background-color: #48ffaf;
        padding-left: .5vw;
        padding-right: .5vw;
        height: 3vh;
        line-height: 3vh;
        font-size: 1.5vh;
        cursor: pointer;
        margin-bottom: 0.5vh;
        &:last-child{
          margin-bottom: 0;
        }
        &:hover{
          opacity: 0.5;
        }
        &.editing{
          font-size: 1vh;
          height: 2vh;
          line-height: 2vh;
          &.red{
            background-color: $red_color;
            color: #000

          }
        }
      }
    }

  }

}
</style>
