<template>
  <div class="techno-slider radius">
    <div class="title" v-if="label == ''">
      {{this.skills ? 'Skills' : 'Technologies'}}
    </div>
    <div class="title label" v-else>
      {{this.label}}
    </div>
    <splide class="tech-list"
      :options="{gap: '1vh', pagination:false, autoWidth: true}"
      v-if="techList"
      :slides="techList"
    >
      <splide-slide v-for="tech in techList" :key="tech" class="tech-entry box-shadow">
        {{tech}}
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'TechnologySlider',
  components: {
    SplideSlide,
    Splide,
  },
  props: ['techList', 'skills', 'label'],
};
</script>

<style lang="scss">
  @import "@/assets/scss/_splide.scss";
  @import "@/assets/scss/_variables.scss";

.techno-slider{
  width: calc(100% - 2vh);
  padding-bottom: 2vh;
  background-color: $new_bg_grey;
  padding: 1vh;
  margin-top: 1vh;
  .splide__arrow{
    top: 0%;
    background-color: #48ffaf;
    opacity: 1;
    margin-bottom: 1vh;
  }
  .splide__arrow--prev{
    right:3em;
    left: auto;
  }
  .splide__list{
    margin-top: 1vh !important;
  }
  .title{
    color: $logo_color;
    position: relative;
    &.label{
      font-weight: 600;
    }
  }
  .splide__track{
    padding: 0 0.5vh;
  }
  .tech-list{
    height: 5vh;
    margin-top: 1vh;
    .tech-entry{
      font-size: 1.2vh;
      font-weight: 600;
      width: 5vw;
      background-color: #fff;
      text-align: center;
      height: 4vh;
      line-height: 4vh;
      margin-right: 1vw;
      margin-bottom: 1vh;
      position: relative;
      border-radius: 5px;
      &:last-child{
        margin-right: 0;
      }
    }

  }
  .splide__arrows{
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    display: flex;
    // max-width: 10vh;
    .splide__arrow{
      position: relative;
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      background-color: #48ffaf;
      opacity: 1;
      transform: translateY(0);
      margin: 0;
      &.splide__arrow--prev{
        margin-right: 1vh;
      }
    }
  }
}

</style>
