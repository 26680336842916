<template>
  <div class="add-button-big box-shadow" @click="this.add" >
    <img :src="require('@/assets/pictos/picto_plus.svg')"/>
    <div class="subtitle">
      {{this.label}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddButtonBig',
  props: {
    add: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: 'Add',
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.add-button-big{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: calc(100% - 4vw);
  margin-bottom: 1vh;
  padding: 0vh;
  position: relative;
  background-color:  $green_color;
  border: 0.1vh solid $green_color;
  border-radius: 1vh;
  cursor: pointer;
  transition: all .2s;
  &:hover{
    background-color: transparent;
  }
  img{
    width: 8vh;
    height: 8vh;
    position: relative;
    font-size: 0;
    top: 0.2vh;
  }
  .subtitle{
    height: 5vh;
    line-height: 5vh;
    position: relative;

  }
}

</style>
