<template>
  <div class="techno-container">
    <div class="techno-list radius" v-show="technologies.length > 0">
      <div v-for="(techno, index) in technologies" :key="techno" class="techno-entry box-shadow">
        <img
          v-if="getPictoTechno(techno)"
          :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
        >
        <img
          v-else
          :src="require(`@/assets/pictos/technos/unspecified.svg`)"
        >
        <div class="techno-name">
          {{techno}}
        </div>
        <!-- {{techno}} -->
        <div class="delete-x" v-if="editing" @click="() => deleteTech(index)">
          <img :src="require('@/assets/pictos/picto_close.svg')"/>
        </div>
      </div>
      <div class="btn-green edit-button" @click="toggleEdit">
        {{this.editing? 'Submit': 'Edit'}}
      </div>
    </div>
    <big-add :label="'Add New Technology'" :add="toggleAdd" v-if="!this.adding" />
    <company-attr v-model="newTech" label="New Technology" :start-edit="true" v-if="this.adding"
      @submitted="this.addNew" :type="'autocomplete'" :autoCompleteTerms="technoList"
      @editing="() => {this.adding = false; this.newTech = ''}"/>
  </div>
</template>

<script>
import BigAdd from '@/components/UniversalComponents/Settings/_addButtonBig.vue';
import CompanyAttr from '@/components/EmployeeComponents/CompanySettings/companyAttr.vue';
import Technos from '@/components/TalentComponents/talentTechnos/talentTechnoList';

export default {
  name: 'TechnologySelect',
  components: {
    BigAdd,
    CompanyAttr,
  },
  props: {
    technologyList: {
      type: Array,
      default: () => [],
    },
    update: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      technologies: [],
      editing: false,
      adding: false,
      newTech: '',
      technoList: Technos.map((t) => t.text),
    };
  },
  methods: {
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(Technos, { text: tech }).length > 0) {
        return this.findByMatchingProperties(Technos, { text: tech })[0].picto;
      }
      return false;
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
    updateTech() {
      this.technologies = [...this.technologyList];
    },
    deleteTech(index) {
      this.technologies.splice(index, 1);
    },
    toggleEdit() {
      if (this.editing) {
        this.update(this.technologies).then(() => {
          this.updateTech();
        });
      }
      this.editing = !this.editing;
      this.adding = false;
    },
    toggleAdd() {
      this.adding = !this.adding;
      this.editing = true;
    },
    addNew() {
      this.technologies.push(this.newTech);
      this.newTech = '';
    },
  },
  mounted() {
    this.updateTech();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.techno-container{
  width: calc(100% - calc(3vw + 2vh));
  position: relative;
  margin-bottom: 1vh;
  .add-button-big{
    width: 100% !important;
  }
  .company-attr{
    width: 98% !important;
  }
  .techno-list{
    background-color: $new_bg_grey;
    display: flex;
    width: calc(100% - 5vh);
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 2vh;
    padding-right: 3vh;
    padding-bottom: 5.5vh;
    position: relative;
    min-height: 8vh;
    margin-bottom: 2vh;

    .edit-button{
      position: absolute;
      right: 1vh;
      bottom:1vh;
      border-radius: 5px !important;

    }
    .techno-entry{
      font-size: 1.1vh;
      font-weight: 600;
      // min-width: 5vw;
      background-color: #fff;
      text-align: center;
      padding: 1.5vh;
      margin-right: 1vw;
      margin-bottom: 1vh;
      position: relative;
      border-radius: 5px;
      width: calc(calc(100% - 11vw) / 12);
      // width: auto;
      &:last-child{
        margin-right: 0;
      }
      img{
        width: 100%;
      }
      .delete-x{
        cursor: pointer;
        position: absolute;
        right: -2vh;
        top: -1.5vh;
        justify-content: center;
        width: 3vh;
        height: 3vh;
        padding: 5px;
        border-radius: 50%;
        img{
          width: 200%;
          height: auto;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

        }
      }
    }
  }
}

</style>
